<template>
    <section class="tt-center">
        <Nav
            class="mt-5 mb-4"
            route-name="StrategicProjects"
            parent-page-name="Стратегические проекты"
            current-page-name="Передовая инженерная школа"
        />

        <div class="tt-center__main-img eng-school d-none d-xl-flex">
            <div class="tt-center__blurred-img eng-school mt-lg-auto ms-auto d-flex">
                <h2 class="tt-center__title mt-lg-auto">
                    Передовая инженерная школа
                </h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="d-block ms-auto mt-auto mb-3 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="col-12 mt-5">
            <div class="row d-flex align-items-center mx-1">
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <img class="w-100" src="../../assets/school-about.png" alt="">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <h3 class="tt-center-goal__title text-center text-lg-start mx-3">
                        О проекте
                    </h3>

                    <p class="tt-center-goal__text mt-3 mx-3">
                        Передовая инженерная школа Университета ИТМО – это школа проектного типа, создаваемая для подготовки инженеров с уровнем компетенций, отвечающим задачам ускоренного трансфера новых (прежде всего - цифровых) технологий в реальный сектор экономики. Отличительной особенностью этой школы является система подготовки специалистов с инженерными, управленческими и гибкими (надпредметными) навыками. Целью такой системы подготовки является выпуск специалистов новой формации – инженеров категории «лидер инженерной группы» (англ. Principal Engineer, PE), которые по завершению обучения, в зависимости от индивидуальных личностных качеств, способностей и предрасположенностей.
                    </p>
                </div>
            </div>
        </div>

        <div class="col-12 mt-5">
            <div class="row mx-1">
                <div class="col-12 col-md-6">
                    <h3 class="tt-center-goal__title text-center text-lg-start">
                        Партнёры
                    </h3>
                </div>
                <div class="col-12 col-md-6">
                    <p class="tt-center-goal__text mx-3">
                        Университет ИТМО стал победителем федерального проекта по созданию передовых инженерных школ (ПИШ). ПИШ ИТМО создана в кооперации с ПАО «Татнефть» и ПАО «Газпром нефть», индустриальные партнеры ИТМО обеспечат софинансирование проекта в размере двух миллиардов рублей до 2030 года.
                    </p>
                </div>
            </div>

            <div class="row d-flex justify-content-between mt-5">
                <div class="col-12 col-md-6">
                    <div class="d-flex justify-content-center align-items-center p-3"  style="background: #f2f2f2;height:100%">
                        <img style="width: 100%" src="../../assets/tatneft.png" alt="">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex justify-content-center align-items-center p-3" style="background: #f2f2f2;height:100%">
                        <img src="../../assets/gpn.svg" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="tt-center-goal-block d-flex flex-column flex-lg-row align-items-center p-5 mx-0">
            <div class="col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
                <h3 class="tt-center-goal__title text-center text-lg-start">
                    Основные направления
                </h3>

                <div class="tt-center-goal__text mt-3">
                    Общим для всех направлений учебной программы является получение компетенций в области сквозных цифровых технологий:

                    <ul class="list-unstyled school-list my-4 ms-3">
                        <li class="school-list__item my-3">Генеративный дизайн</li>
                        <li class="school-list__item my-3">Блокчейн</li>
                        <li class="school-list__item my-3">Машинное обучение</li>
                        <li class="school-list__item my-3">Системы управления большими данными</li>
                        <li class="school-list__item my-3">Искусственный интеллект</li>
                    </ul>

                    Например, инженеры нового поколения будут способны принимать управленческие решения с применением современных алгоритмов, основывающихся на больших данных (data driven подход).
                </div>
            </div>

            <div class="col-12 col-lg-6 d-flex justify-content-center rounded">
                <div class="d-flex justify-content-center">
                    <img
                        src="../../assets/directs.svg"
                        alt=""
                    >
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import { onMounted } from "vue";
import routerMixin from "@/mixins/routerMixin";
import Nav from "@/components/Nav/Nav";

export default {
    name: "EngineeringSchool",

    components: {
        Nav
    },

    setup() {
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })
    }
}
</script>

<style>
.school-list__item {
    display: flex;
    align-items: center;
}
.school-list__item::before {
    content: url('../../assets/school-list-marker.svg');
    width: 27px;
    height: 27px;
    margin-right: 15px;
}

.tt-center__main-img.eng-school {
    background-image: url('../../assets/engineering-school.png')
}

.tt-center__blurred-img.eng-school {
    background-image: url('../../assets/engineering-school-blur.png')
}
</style>